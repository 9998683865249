import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useRef } from "react";
import FacephiLogo from "./assets/facephi.svg";

const encryptXor = (text, key) => {
  let enc = new TextEncoder(); // always utf-8

  let textArr = enc.encode(text);
  let result = new Uint8Array(text.length);

  for (let i = 0; i < textArr.length; i++) {
      result[i] = textArr[i] ^ key.charCodeAt(i % key.length)
  }

  return result;
}

const decryptXor = (arrBuf, key) => {
  let resultArr = new Uint8Array(arrBuf.length);

  for (let i = 0; i < arrBuf.length; i++) {
    resultArr[i] = arrBuf[i] ^ key.charCodeAt(i % key.length);
  }

  let dec = new TextDecoder("utf-8");
  let resultArrDec = dec.decode(resultArr);
  // Select the container in which you want to insert the elements.
  let container = document.getElementById('dataDescompuesto');
  let resultObj = JSON.parse(resultArrDec);
  separateInContainer(resultObj, container);

  return dec.decode(resultArr);
}

const separateInContainer = (objeto, container) => {
  for (let key in objeto) {
    if (typeof objeto[key] === "object" && objeto[key] !== null) {
      // If the property is an object, create a new container.
      let subContainer = document.createElement("div");
      subContainer.textContent = key + ":";
      container.appendChild(subContainer);
      // Call the function recursively for the nested object
      separateInContainer(objeto[key], subContainer);
    } else {
      // If the property is not an object, create an element for it.
      let div = document.createElement("div");
      div.textContent = key + ": " + objeto[key];
      container.appendChild(div);
    }
  }
}


// This function converts an array of numeric values (such as you might get from a Uint8Array) to a hexadecimal string.
function a2hex(arrBuf) {
  return [...new Uint8Array(arrBuf)]
    .map(x => x.toString(16).padStart(2, '0'))
    .join('');
}
// This function converts a hexadecimal string to an array of numeric values (such as you might get from a Uint8Array).
function hex2a(hexx) {
  return new Uint8Array(hexx.match(/../g).map(h=>parseInt(h,16)))
}


const App = () => {
  const rawData = useRef();
  const rawLicense = useRef();
  const outputLicense = useRef();
  const generateButton = useRef();
  const license = useRef();

  const providerLicense = useRef();
  const customerName = useRef();
  const urlBasePath = useRef();
  const expirationDate = useRef();
  const comments = useRef();

  const XOR_KEY = 'FacePhi';

  const generateLicense = () => { 
    return {
      customer: customerName.current.value,
      documentType: "LicenseEnvelope",
      license: {
        comments: comments.current.value,
        dateEnd: expirationDate.current.value,
        extraData: a2hex(encryptXor(JSON.stringify(plainEnvolveTextLicense()), XOR_KEY)).toUpperCase(),
        licenseDocument: true,
        licenseFacial: false,
        licenseTokenDocument: true,
        logging: false,
        os: "Any",
        packageName: urlBasePath.current.value,
        engineType: document.querySelector('input[name="engine-type"]:checked').value,
        product: "SelphID",
        version: "1.0"
      },
      provider: "FacePhi"
    }
  }

  const plainTextLicense = () => {
    return {
      customer: customerName.current.value,
      documentType: "LicenseEnvelope",
      license: {
        comments: comments.current.value,
        dateEnd: expirationDate.current.value,
        licenseDocument: providerLicense.current.value,
        packageName: urlBasePath.current.value,
        engineType: document.querySelector('input[name="engine-type"]:checked').value,
        product: "SelphID",
        version: "1.0"
      },
      provider: "FacePhi"
    }
  }

  const plainEnvolveTextLicense = () => {
    return {
      licenseDocument: providerLicense.current.value,
      licenseTokenDocument: JSON.stringify(plainTextLicense()),
      logging: false,
      engineType: document.querySelector('input[name="engine-type"]:checked').value
    }    
  }
  return (
    <div className="App">
      <header className="bg-primary">
        <div className="container p-3 d-flex align-items-center justify-content-between">
          <img alt="Facephi" src={FacephiLogo}></img>
        </div>
      </header>
      <main className="container row">
        <section className="col-sm-6 align-items-stretch">
          <fieldset className="form-group">
            <legend>License Web</legend>

            <div className="form-row">
              <label htmlFor="provider-license" className="col">Provider License Key</label>
              <input ref={providerLicense} id="provider-license" className="form-control col" type="text"></input>
            </div>

            <div className="form-row">
              <label htmlFor="customer-name" className="col">Customer Name</label>
              <input ref={customerName} id="customer-name" className="form-control col" type="text"></input>
            </div>

            <div className="form-row">
              <label htmlFor="url-base-path" className="col">URL Base path</label>
              <input ref={urlBasePath} id="url-base-path" className="form-control col" type="text"></input>
            </div>
            <div className="form-row">
              <label htmlFor="expiration-date" className="col">Expiration Date</label>
              <input ref={expirationDate} id="expiration-date" type="date" className="col"></input>
            </div>

            <div className="form-row">
              <label htmlFor="comments" className="col">Comments</label>
              <input ref={comments} id="comments" className="form-control col" type="text"></input>
            </div>

            <div className="form-row">
              <label htmlFor="engine-type" className="col">Engine Type</label>

              <div className="form-check form-check-inline col">
                <input className="form-check-input" type="radio" name="engine-type" id="facephi" value="Facephi" />
                <label className="form-check-label" htmlFor="facephi">Facephi</label>
              </div>
              <div className="form-check form-check-inline col">
                <input className="form-check-input" type="radio" name="engine-type" id="microblink" value="Microblink" checked />
                <label className="form-check-label" htmlFor="microblink" selected>Microblink</label>
              </div>
              <div className="form-check form-check-inline col">
                <input className="form-check-input" type="radio" name="engine-type" id="facephiDocumentReader" value="FacephiDocumentReader" />
                <label className="form-check-label" htmlFor="facephiDocumentReader" selected>FacephiDocumentReader</label>
              </div>
              <div className="form-check form-check-inline col">
                <input className="form-check-input" type="radio" name="engine-type" id="dummy" value="Dummy"/>
                <label className="form-check-label" htmlFor="dummy">Dummy</label>
              </div>
              <div className="form-check form-check-inline col">
                <input className="form-check-input" type="radio" name="engine-type" id="regula" value="Regula"/>
                <label className="form-check-label" htmlFor="regula">Regula</label>
              </div>
            </div>

            <button ref={generateButton} type="button" className="form-row btn btn-dark" onClick={() => {
              try {
                const rawCurrent = rawLicense.current;
                const outputCurrent = outputLicense.current;
                
                console.log("Generating license key...");

                const generateKey = a2hex(encryptXor(JSON.stringify(generateLicense()), XOR_KEY))

                rawCurrent.value = decryptXor(hex2a(generateKey), XOR_KEY)
                outputCurrent.value = generateKey.toString().toUpperCase()
              } catch (e) {
                console.log(e)
                alert("Generating Error: The license can not be generated because some field is missing!");
              }

            }}>Generate License</button>
        
          </fieldset>
        </section>
        <section className="col-sm-6">
          <fieldset>
            <legend>Output Raw license</legend>

            <textarea style={{"width": "100%"}} ref={rawLicense}></textarea>
          </fieldset>
          <fieldset>
            <legend>Output Base64 license</legend>

            <textarea style={{"width": "100%"}} ref={outputLicense}></textarea>
          </fieldset>
        </section>

        <section className="col-sm-6 align-items-stretch">

          <fieldset>
              <legend>License</legend>

              <textarea style={{"width": "100%"}} ref={license}></textarea>
            </fieldset>

            <fieldset>
              <legend>Output Raw Data</legend>

              <textarea style={{"width": "100%"}} ref={rawData}></textarea>
            </fieldset>

              <button ref={generateButton} type="button" className="form-row btn btn-dark m-5" onClick={() => {
                try {
                  const rawDataCurrent = rawData.current;
                  const licenseCurrent = license.current;
                  console.log("Holaa: ", licenseCurrent.value);
                  console.log("Generating license key...");

                  rawDataCurrent.value = decryptXor(hex2a(licenseCurrent.value), XOR_KEY)
                } catch (e) {
                  console.log(e)
                  alert("Generating Error: The license can not be generated because some field is missing!");
                }
              }}>Decript License</button>
        </section>
        <section>
          <div className="form-row m-5">
            <legend>Data descompuesto</legend>
            <div style={{"width": "100%"}} id="dataDescompuesto">
            </div>
          </div>
        </section>
      </main>
      <footer className="bg-primary text-white fixed-bottom">
        <div className="container px-3 d-flex justify-content-between align-items-center">
          <small>2023 ©FacePhi. All rights reserved.</small>
          <div className="small font-weight-bold px-3 py-2 bg-warning text-dark">Licensing Tool</div>
        </div>
      </footer>
    </div>
  );
}

export default App;
